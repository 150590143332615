import { PricePlanName } from "@internal/rest/generated/schemas";

export function isFounderPricing(pp: PricePlanName | undefined): boolean {
  if (
    pp === PricePlanName.founder_2023 ||
    pp === PricePlanName.founder_2023_07 ||
    pp === PricePlanName.founder_2023_09 ||
    pp === PricePlanName.founder_2024
  ) {
    return true;
  }

  return false;
}
