import {
  PricePlanName,
  SubscriptionTier,
} from "@internal/rest/generated/schemas";
import { exhaustiveCheck } from "@internal/utils/types";

export function isLegacyPlanWithAdditionalFees(
  pricePlan: PricePlanName | undefined
): boolean {
  switch (pricePlan) {
    case PricePlanName.founder_2023_09:
    case PricePlanName.base_2023:
    case PricePlanName.core_2023_monthly:
    case PricePlanName.core_2023:
    case PricePlanName.headliner_2023:
    case PricePlanName.headliner_2023_monthly:
      return true;
    default:
      return false;
  }
}

export function isSubscribed(tier: SubscriptionTier): boolean {
  switch (tier) {
    case SubscriptionTier.BASE_2023:
    case SubscriptionTier.SYNDICATE_2024:
      return false;
    case SubscriptionTier.CORE_2023:
    case SubscriptionTier.CORE_2023_MONTHLY:
    case SubscriptionTier.HEADLINER_2023:
    case SubscriptionTier.HEADLINER_2023_MONTHLY:
    case SubscriptionTier.SYNDICATE_PLUS_2024:
    case SubscriptionTier.INSTITUTIONAL_2024:
      return true;
    default:
      return false;
  }
}

export function mapSubscriptionToPricePlan(
  tier: SubscriptionTier
): PricePlanName {
  switch (tier) {
    case SubscriptionTier.BASE_2023:
      return PricePlanName.base_2023;
    case SubscriptionTier.CORE_2023:
    case SubscriptionTier.CORE_2023_MONTHLY:
      return PricePlanName.core_2023;
    case SubscriptionTier.HEADLINER_2023:
    case SubscriptionTier.HEADLINER_2023_MONTHLY:
      return PricePlanName.headliner_2023;
    case SubscriptionTier.SYNDICATE_2024:
      return PricePlanName.syndicate_2024;
    case SubscriptionTier.SYNDICATE_PLUS_2024:
      return PricePlanName.syndicate_plus_2024;
    case SubscriptionTier.INSTITUTIONAL_2024:
      return PricePlanName.institutional_2024;
    default:
      return PricePlanName.syndicate_2024 as never;
  }
}

export function isActivePricing(plan: PricePlanName) {
  switch (plan) {
    case "syndicate_2024":
    case "syndicate_plus_2024":
    case "institutional_2024":
    case "founder_2024":
      return true;
    default:
      return false;
  }
}

export function isBrandedPricePlan(pp: PricePlanName | undefined): boolean {
  switch (pp) {
    case PricePlanName.core_2023:
    case PricePlanName.core_2023_monthly:
    case PricePlanName.headliner_2023:
    case PricePlanName.headliner_2023_monthly:
    case PricePlanName.syndicate_plus_2024:
    case PricePlanName.institutional_2024:
      return true;
    default:
      return false;
  }
}

export const activeSyndicatePricePlans = [
  PricePlanName.syndicate_2024,
  PricePlanName.syndicate_plus_2024,
  PricePlanName.institutional_2024,
];

export const activeFounderPricePlan = [PricePlanName.founder_2024];

export function isPlanWithOffPlatformDeals(pp: PricePlanName | undefined) {
  return pp === PricePlanName.institutional_2024;
}

export function mapActivePricePlanNames(plan: PricePlanName) {
  switch (plan) {
    // legacy
    case "base_2023":
      return "Base 2023";
    case "branded_2022":
      return "Branded 2022";
    case "founder_2022":
      return "Founder 2022";
    case "standard_2022":
      return "Standard 2022";
    case "branded_2023":
      return "Branded 2023";
    case "founder_2023":
      return "Founder 2023";
    case "standard_2023":
      return "Standard 2023";
    case "core_2023":
      return "Core 2023";
    case "headliner_2023":
      return "Headliner 2023";
    case "headliner_2023_monthly":
      return "Headliner 2023 Monthly";
    case "core_2023_monthly":
      return "Core 2023 Monthly";
    case "entry_2020":
      return "Entry 2020";
    case "standard_2020":
      return "Standard 2020";
    case "founder_2020":
      return "Founder 2020";
    case "founder_2023_07":
      return "Founder 2023";
    case "founder_2023_09":
      return "Founder 2023";

    // newish
    case "syndicate_2024":
      return "Syndicate 2024";
    case "syndicate_plus_2024":
      return "Syndicate Plus 2024";
    case "institutional_2024":
      return "Institutional 2024";
    case "founder_2024":
      return "Founder 2024";
    default:
      return exhaustiveCheck(plan, { fallback: "Unknown plan" });
  }
}
